import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router-dom';

export default function App() {

	return (
		<HelmetProvider>
			<div className="app">
				<Toaster position="bottom-center" reverseOrder={false}/>
				<Outlet />
			</div>
		</HelmetProvider>
	);
}