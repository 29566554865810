import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.scss';
import App from './app';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CreatePage from './pages/create.page';
import ErrorPage from './pages/error.page';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);
ReactGA.send("pageview");

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <CreatePage />
      },
      {
        path: "/create",
        element: <CreatePage />
      },
      {
        path: "/create/:id",
        element: <CreatePage />
      }
    ]
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);