import { useRouteError } from "react-router-dom";
import { HeaderComponent } from "../components/header.component";

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <>
        <HeaderComponent />
        
        <div className="app-content">
            <div id="error-page">
        
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>{error.statusText || error.message}</i>
                </p>
            </div>
        </div>
    </>
  );
}