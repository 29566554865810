import './header.component.scss';
import { Link } from "react-router-dom";

export function HeaderComponent() {
    return (
        <header className="app-header">
            <nav className="nav">
                <span className="page-title">
                    CSS Shadows
                </span>
                <div className="nav-item">
                    <Link to="/">Home</Link>
                    <Link to="/create">Create</Link>
                </div>
            </nav>
		</header>
    )
}