import './create.page.scss';
import { useEffect, useState } from "react";
import { redirect, useLocation } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegObjectUngroup } from "react-icons/fa";
import { FaWrench } from "react-icons/fa6";
import { FaRegSquarePlus } from "react-icons/fa6";
import toast from "react-hot-toast";
import dataJSON from '../data/cssboxshadows.json';
import { HeaderComponent } from "../components/header.component";
import { FaRegClone } from "react-icons/fa";


export default function CreatePage() {
    const location = useLocation();
    const [ valueObject, setValueObject ] = useState({
        'w': 120,
        'wunit': 'px',
        'h': 120,
        'hunit': 'px',
        'backgroundcolor': '#ffffff',
        'bordersize': 0,
        'bordersizeunit': 'px',
        'bordercolor': '#ffffff',
        'borderradius': 0,
        'borderradiusunit': 'px'
    })
    
    const [valueShadows, setValueShadows] = useState([
        {
            'color': '#999999',
            'x': '3',
            'xunit': 'px',
            'y': '3',
            'yunit': 'px',
            'blur': '9',
            'blurunit': 'px',
            'radius': '0',
            'radiusunit': 'px',
            'inset': false,
            'visible': true
        }
    ]);
    const [valueBoxShadowStyle, setBoxShadowStyle] = useState("")

    const UNITS = [
        'cm',
        'mm',
        'in',
        'pt',
        'px',
        'em',
        'rem',
    ]

    const SHADOW = {
        'color': '#ffffff',
        'x': '0',
        'xunit': 'px',
        'y': '0',
        'yunit': 'px',
        'blur': '0',
        'blurunit': 'px',
        'radius': '0',
        'radiusunit': 'px',
        'inset': false,
        'visible': true
    }

	const MAX = 500;
    const SHADOWMAXX = 500;
    const SHADOWMAXY = 500;
    const SHADOWMAXBLUR = 500;
    const SHADOWMAXRADIUS = 500;

    useEffect(() => {
        try {
            let id = location.pathname.split("/")[2]
            if (id !== undefined) {
                
                id = id-1
                if (dataJSON[id].config !== undefined) {
                    setValueShadows(dataJSON[id].config)
                } else {
                    redirect("/create");    
                }
            } else {

                redirect("/create");
            }   
        } catch (error) {
            redirect("/create");
        }
        
    }, [location])

    useEffect(() => {
        let boxShadowStyle = ""
        valueShadows.map((s) => {
            if (s.visible === true) {
                let i = s['x'] + s['xunit'] + " " + s['y'] + s['yunit'] + " " + s['blur'] + s['blurunit'] + " " + s['radius'] + s['radiusunit'] + " " + s['color']
                if (s.inset) {
                    i = i + " inset"
                }
                if (boxShadowStyle === "") {
                    boxShadowStyle = i
                } else {
                    boxShadowStyle = boxShadowStyle + ", "  + i
                }
            }
            return null
        })
        setBoxShadowStyle(boxShadowStyle)
    }, [valueShadows])

    function styleDemo() {
        return { 
            width: `${(valueObject.w)}${(valueObject.wunit)}`, 
            height: `${(valueObject.h)}${(valueObject.hunit)}`,
            border: `${(valueObject.bordersize)}${(valueObject.bordersizeunit)} solid ${(valueObject.bordercolor)}`,
            borderRadius: `${(valueObject.borderradius)}${(valueObject.borderradiusunit)}`,
            backgroundColor: `${(valueObject.backgroundcolor)}`,
            boxShadow: `${(valueBoxShadowStyle)}`
        }
    }

    function addShadow() {
        setValueShadows([...valueShadows, SHADOW])
    }

    function removeShadow(index, e) {
        e.preventDefault();
        let shadows = [...valueShadows]
        shadows.splice(index,1)
        setValueShadows(shadows)
    }

    function handleObjectFieldChange(event) {
        if (['w', 'wunit', 'h', 'hunit', 'backgroundcolor', 'bordersize', 'bordersizeunit', 'bordercolor', 'borderradius', 'borderradiusunit'].includes(event.target.name)) {
            let object = {...valueObject}
            object[event.target.name] = event.target.value;
            setValueObject({...object})
        }
    }


    function handleShadowFieldChange(event, index) {
        if (['color', 'x', 'y', 'blur', 'radius', 'xunit', 'yunit', 'blurunit', 'radiusunit'].includes(event.target.name)) {
            let shadows = [...valueShadows]
            shadows[index][event.target.name] = event.target.value;
            setValueShadows([...shadows])
        }
        if (['inset'].includes(event.target.name)) {
            let shadows = [...valueShadows]
            shadows[index][event.target.name] = event.target.checked;
            setValueShadows([...shadows])
        }
        if (['visible'].includes(event.target.name)) {
            let shadows = [...valueShadows]
            shadows[index][event.target.name] = !shadows[index][event.target.name];
            setValueShadows([...shadows])
        }
    }

    function handleCopyStyle() {
        let style = ""
        style += `width: ${(valueObject.w)} ${(valueObject.wunit)};\n`
        style += `height: ${(valueObject.h)} ${(valueObject.hunit)};\n`
        style += `border: ${(valueObject.bordersize)} ${(valueObject.borderunit)} solid ${(valueObject.bordercolor)};\n`
        style += `border-radius: ${(valueObject.borderradius)} ${(valueObject.borderradiusunit)};\n`
        style += `background-color: ${(valueObject.backgroundcolor)};\n`
        if (valueBoxShadowStyle !== "") {
            style += `box-shadow: ${(valueBoxShadowStyle)};`
        }

        navigator.clipboard.writeText(style)
        
        toast.success("Style copied to clipboard!");
    }

    function handleCopyShadow() {
        let style = ""
        if (valueBoxShadowStyle !== "") {
            style += `box-shadow: ${(valueBoxShadowStyle)};`
            navigator.clipboard.writeText(style);
            toast.success("Shadow style copied to clipboard!" );
        } else {
            toast("No shadows are configured!")
        }
    }

    function cloneShadow(e, index) {
        let shadows = valueShadows.map(i => ({...i}))
        setValueShadows([...valueShadows, shadows[index]])
    }

	return (
		<>
			<HeaderComponent />
			
			<div className="app-content">
                <div className="page create">
                    
                    <div className="canvas">
                        <div className="demo" style={styleDemo()}>
                        </div>
                    </div>
                    
                    <div className="settings">
                        
                        <div className="settings-object">
                            <div className="heading">
                                <FaRegObjectUngroup />
                                <span>Object</span>
                            </div>
                            <label htmlFor="backgroundcolor">
                                <span>Background Color</span>
                                <input id="backgroundcolor" type="color" name="backgroundcolor" onChange={(e) => handleObjectFieldChange(e)} value={valueObject.backgroundcolor}/>
                            </label>

                            <label htmlFor="w">
                                <span>Width</span>
                                <div>
                                    <input id="w" type="number" dir="rtl" min="0" name="w" max={MAX} onChange={(e) => handleObjectFieldChange(e)} value={valueObject.w}/>
                                    <select name="wunit" value={valueObject.wunit} onChange={(e) => handleObjectFieldChange(e)}>
                                        {UNITS.map((unit, i) => {
                                            return(
                                                <option key={i} value={unit}>{unit}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </label>
                            <label htmlFor="h">
                                <span>Height</span>
                                <div>
                                    <input id="h" type="number" dir="rtl" min="0" name="h" max={MAX} onChange={(e) => handleObjectFieldChange(e)} value={valueObject.h}/>
                                    <select name="hunit" value={valueObject.hunit} onChange={(e) => handleObjectFieldChange(e)}>
                                        {UNITS.map((unit, i) => {
                                            return(
                                                <option key={i} value={unit}>{unit}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </label>

                            <label htmlFor="bordercolor">
                                <span>Border Color</span>
                                <input id="bordercolor" type="color" name="bordercolor" onChange={(e) => handleObjectFieldChange(e)} value={valueObject.bordercolor}/>
                            </label>
                            <label htmlFor="bordersize">
                                <span>Border</span>
                                <div>
                                    <input id="bordersize" type="number" dir="rtl" min="0" name="bordersize" max={MAX} onChange={(e) => handleObjectFieldChange(e)} value={valueObject.bordersize}/>
                                    <select name="bordersizeunit" value={valueObject.bordersizeunit} onChange={(e) => handleObjectFieldChange(e)}>
                                        {UNITS.map((unit, i) => {
                                            return(
                                                <option key={i} value={unit}>{unit}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </label>
                            <label htmlFor="borderradius">
                                <span>Radius</span>
                                <div>
                                    <input id="borderradius" type="number" dir="rtl" min="0" name="borderradius" max={MAX} onChange={(e) => handleObjectFieldChange(e)} value={valueObject.borderradius}/>
                                    <select name="borderradiusunit" value={valueObject.borderradiusunit} onChange={(e) => handleObjectFieldChange(e)}>
                                        {UNITS.map((unit, i) => {
                                            return(
                                                <option key={i} value={unit}>{unit}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </label>
                        </div>
                        <hr/>
                        <div className="settings-shadows">
                            <div className="heading">
                                <div>
                                    <FaWrench />
                                    <span>Shadows</span>
                                </div>
                                <button type="button" onClick={(e) => addShadow(e)}><FaRegSquarePlus /></button>
                            </div>
                            
                            { valueShadows.map((item, index) => {
                                return (
                                    <div key={index} className="shadowForm">
                                        <div className="heading">
                                            <h4>
                                                Shadow #{index+1}
                                            </h4>
                                            <div className="actions">
                                            <button type="button" onClick={(e) => removeShadow(index, e)}><FaRegTrashAlt /></button> 
                                                <button id={"clone-" + index} type="button" name="clone" onClick={(e) => cloneShadow(e, index)}><FaRegClone /></button>
                                                <button id={"visible-" + index} type="button" name="visible" onClick={(e) => handleShadowFieldChange(e, index)}>{item.visible ? <FaRegEye /> : <FaRegEyeSlash />}</button>
                                            </div>
                                        </div>

                                        <label htmlFor={"color-" + index}>
                                            <span>Color</span>
                                            <input id={"color-" + index} type="color" name="color" value={item.color} onChange={(e) => handleShadowFieldChange(e, index)}/>
                                        </label>
                                        <label htmlFor={"x-" + index}>
                                            <span>x Offset</span>
                                            <div>
                                            <input id={"x-" + index} type="number" dir="rtl" name="x" min={-SHADOWMAXX} max={SHADOWMAXX} step="0.1" value={item.x} onChange={(e) => handleShadowFieldChange(e,index)}/>
                                                <select id={"xunit-" + index} name="xunit" value={item.xunit} onChange={(e) => handleShadowFieldChange(e,index)}>
                                                    {UNITS.map((unit, i) => {
                                                        return(
                                                            <option key={i} value={unit}>{unit}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </label>
                                        <label htmlFor={"y-" + index}>
                                            <span>y Offset</span>
                                            <div>
                                                <input id={"y-" + index} type="number" dir="rtl" name="y" min={-SHADOWMAXY} max={SHADOWMAXY} step="0.1" value={item.y} onChange={(e) => handleShadowFieldChange(e,index)}/>
                                                <select id={"yunit-" + index} name="yunit" value={item.yunit} onChange={(e) => handleShadowFieldChange(e,index)}>
                                                    {UNITS.map((unit,i) => {
                                                        return(
                                                            <option key={i} value={unit}>{unit}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </label>
                                        <label htmlFor={"blur-" + index}>
                                            <span>Blur</span>
                                            <div>
                                                <input id={"blur-" + index} type="number" dir="rtl" name="blur" min="0" max={SHADOWMAXBLUR} value={item.blur} onChange={(e) => handleShadowFieldChange(e,index)}/>
                                                <select id={"blurunit-" + index} name="blurunit" value={item.blurunit} onChange={(e) => handleShadowFieldChange(e,index)}>
                                                    {UNITS.map((unit,i) => {
                                                        return(
                                                            <option key={i} value={unit}>{unit}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </label>
                                        <label htmlFor={"radius-" + index}>
                                            <span>Radius</span>
                                            <div>
                                                <input id={"radius-" + index} type="number" dir="rtl" name="radius" min={-80} max={SHADOWMAXRADIUS} value={item.radius} onChange={(e) => handleShadowFieldChange(e, index)}/>
                                                <select id={"radiusunit-" + index} name="radiusunit" value={item.radiusunit} onChange={(e) => handleShadowFieldChange(e, index)}>
                                                    {UNITS.map((unit, i) => {
                                                        return(
                                                            <option key={i} value={unit}>{unit}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </label>
                                        <label htmlFor={"inset-" + index}>
                                            <span>Inset</span>
                                            <input id={"inset-" + index} type="checkbox" name="inset" checked={item.inset} onChange={(e) => handleShadowFieldChange(e, index)}/>
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="settings-actions">
                            <button type="button" onClick={() => handleCopyStyle()}>Copy complete CSS</button>
                            <button type="button" onClick={() => handleCopyShadow()}>Copy only shadow CSS</button>
                        </div>  
                    </div>
                </div>
           </div>

        </>
	);
}